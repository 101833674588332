// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.

export const environment = {
  production: false,
  debug: true,
  hmr: false,
  showBuildVersion: true,
  showDemoPage: 'y',
  identity_provider: 'hk1crm-dev-saml',
  serverUrl: '/api',
  defaultLanguage: 'en_US',
  supportedLanguages: ['en_US'],
  SYSTEM_DATE_FORMAT: 'dd-MM-yyyy',
  SYSTEM_DATE_TIME_FORMAT: 'dd-MM-yyyy HH:mm',
  datepicker_date_format: 'DD-MM-YYYY',
  DISTID: 'E1MXIIDR1QELJ5',
  OAUTH_URL: 'https://dev-api-hk1crm.swireproperties.com/web-dev-1v1/cognito',
  MEMBER_REGISTRATION_LINK: 'https://uat-liveplus-hk1crm.cityplaza.com/en/registration',
  OAUTH_CLIENT_ID: '1elvc1tru0r6do9vuug1h91g0i',
  API_URL: {
    CHANNEL_API: 'https://dev-api-hk1crm.swireproperties.com/web-dev-1v1',
  },
  version: 'dev-934',
  APP_CONFIG: {
    DEFAULT_CURRENCY: 'HKD',
    MAX_AMOUNT: 100000000,
    DEFAULT_INPUT_MAX_LENGTH: 255,
  },
  APP_CHANNEL_NAME: 'HK1CRM',
  APP_BASE_URL: 'https://dev-hk1crm.swireproperties.com',
  DYNATRACE_SCRIPT_URL: './custom-scripts/dynatrace-setup.js',
  FEATURE_FLAGS: {
    RPT: {
      PP: true,
      CP: true,
      CG: true,
    },
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
