import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ReimburseDetail,
  ReimburseImage,
  ReimbursementData,
  ReimbursementSummaryBreakDown,
  ReimbursementSummaryDTO,
  ReimbursementSummaryDetail,
  ReimbursementAccountCode,
  ReimbursementLog,
} from '@app/@core/models';
import { ReimbursementConstants } from '@app/pages/reimbursement/reimbursement.constants';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class ReimbursementService {
  constructor(private httpClient: HttpClient) {}
  public $TransactionTypeChange: BehaviorSubject<string> = new BehaviorSubject<string>(ReimbursementConstants.TRANSACTION_LIST_TYPE.EVOUCHER);
  public $imageUploadChange: Subject<ReimburseImage> = new Subject<ReimburseImage>();
  public revertReimbursementSuccess: Subject<any> = new Subject<any>();
  public imageUploadChange = this.$imageUploadChange.asObservable().pipe(distinctUntilChanged());
  revertReimbursementSuccessSubject(val: string) {
    this.revertReimbursementSuccess.next(val);
  }
  setImageUploadedValue(img: ReimburseImage) {
    this.$imageUploadChange.next(img);
  }
  setTransactionType(val: string) {
    this.$TransactionTypeChange.next(val);
  }
  getTransactionType() {
    return this.$TransactionTypeChange.value;
  }
  getReimbursementList(params: any): Observable<ReimbursementData[]> {
    return this.httpClient.post(`/reimbursement/list`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  getReimbursementDetail(id: any): Observable<ReimburseDetail> {
    return this.httpClient.get(`/reimbursement/detail?transactionId=${id}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  updateDetail(params: any): Observable<any> {
    return this.httpClient.put(`/reimbursement/detail`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  uploadReimbursementImage(data: any): Observable<ReimburseImage> {
    // Put Image Source to FromData
    const formData = new FormData();
    formData.append('bu', data.bu);
    formData.append('lp', data.lp);
    formData.append('reimbursementImage', data.sourceFile, data.sourceFile.name);
    return this.httpClient.post(`/reimbursement/image`, formData).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  reSubmit(param: any) {
    return this.httpClient.post(`/reimbursement/summary/resubmit`, param).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  getHistory(params: any): Observable<ReimbursementLog[]> {
    return this.httpClient.get(`/reimbursement/summary/log`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  getDetailLog(params: any): Observable<ReimbursementLog[]> {
    return this.httpClient.get(`/reimbursement/detail/log`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  getPMS(params: any): Observable<any> {
    return this.httpClient.get(`/reimbursement/list`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  approveReimbursementSummary(params: any): Observable<any> {
    return this.httpClient.post(`/reimbursement/summary/approve`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  rejectReimbursementSummary(params: any): Observable<any> {
    return this.httpClient.post(`/reimbursement/summary/reject`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  submitReimbursement(params: any): Observable<any> {
    return this.httpClient.post(`/reimbursement/submit`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  submitEndorsement(params: any): Observable<any> {
    return this.httpClient.post(`/reimbursement/endorsement/submit`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  getReimbursementSummaryDetail(id: any): Observable<ReimbursementSummaryDetail> {
    return this.httpClient.get(`/reimbursement/summary/detail?reimbursementSummaryId=${id}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  getReimbursemenSummarytList(params: any): Observable<ReimbursementSummaryDTO[]> {
    return this.httpClient.post(`/reimbursement/summary?page=${params.page}&size=${params.size}`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getAccountCodes(params: any): Observable<ReimbursementAccountCode[]> {
    return this.httpClient.get(`/reimbursement/accountCodes`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  getReimbursementSummaryDetailBreakdown(params: any): Observable<ReimbursementSummaryBreakDown[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('reimbursementSummaryId', params.reimbursementSummaryId);
    httpParams = httpParams.append('page', params.page);
    httpParams = httpParams.append('size', params.size);
    httpParams = httpParams.append('order', params.order);
    httpParams = httpParams.append('orderBy', params.orderBy);

    let body = cloneDeep(params);

    delete body.reimbursementSummaryId;
    delete body.page;
    delete body.size;
    delete body.order;
    delete body.orderBy;

    return this.httpClient.post(`/reimbursement/summary/detail/reimbursementBreakdown`, body, { params: httpParams }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  resendEndorsementEmail(params: any) {
    return this.httpClient.post(`/reimbursement/endorsement/resend`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getResubmitReimbursementSummaryBreakdown(params: any): Observable<any> {
    return this.httpClient.post(`/reimbursement/summary/regenerate/breakdown`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  regenerateEndorsementSummary(params: any): Observable<any> {
    return this.httpClient.post(`/reimbursement/summary/regenerate`, params).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
  downloadReimbursementSummary(id: any, isEndorsed: boolean) {
    return this.httpClient.get(`/reimbursement/summary/download?id=${id}&isEndorsed=${isEndorsed ? 1 : 0}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  reSyncToPMSUsingPOST(params: any) {
    return this.httpClient.post(`/reimbursement/reSyncToPMS?reimbursementSummaryId=${params.reimbursementSummaryId}`, null).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    );
  }

  revertReimbursement(params: any) {
    return this.httpClient.post(`/reimbursement/revert`, params).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    );
  }
  transactionsVoid(params: any) {
    return this.httpClient.post(`/reimbursement/voidRequest`, params).pipe(
      map((response: ApiResponse) => {
        return response;
      })
    );
  }

 // 批量更新
 batchRevertTransactions(params: any) {
  return this.httpClient.post(`/reimbursement/transactions/revert`, params).pipe(
    map((response: ApiResponse) => {
      return response;
    })
  );
}


  getInvoiceNumber(params: any) {
    return this.httpClient.get(`/reimbursement/invoiceNumber`, { params }).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }
}
